import React from "react";
import { Link } from "react-router-dom";
import "./BookLink.css";

class BookLink extends React.Component {
  render() {
    return <Link className="book-link" to={`/book/${this.props.book.id}`}>{this.props.book.bookName}</Link>;
  }
}

export default BookLink;
