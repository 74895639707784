import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";

import './ManageUser.css'

export default function ManageUser (props) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const { admin } = jwt.decode(token);
    if (!admin) {
      props.history.push({ pathname: `/` });
    }
    getAllUsersData();
  }, [])

  const getAllUsersData = async () => {
    const { data : { usersData } } = await axios.get(`${process.env.REACT_APP_API_BASE}/users`, {
      headers: {
        "x-logbook-token": sessionStorage.getItem("token"),
      }
    });
    setUsers(usersData);
  }

  return (
    <>
      <h2>User Management</h2>
      <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>EMAIL</th>
                <th>PASSWORD</th>
                <th>ADMIN</th>
                <th>ACTION</th>
            </tr>
        </thead>
        <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    {user.id}
                  </td>
                  <td>
                    {user.email}
                  </td>
                  <td>
                    {user.password.substring(0, 20) + '...'}
                  </td>
                  <td>
                    {user.admin.toString()}
                  </td>
                  <input type="button" value="edit" onClick={() => {props.history.push({ pathname: `/users/${user.id}/edit`})}} />
                </tr>
              ))}
        </tbody>
    </table>
    </>
  );
}
