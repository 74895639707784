import React from "react";
import axios from "axios";
import "./NewBookForm.css";

// Class based component
class NewBookForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newBookName: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.existingBook.bookName !== prevProps.existingBook.bookName) {
      this.setState({ newBookName: this.props.existingBook.bookName || '' });
    }
  }

  handleInput = ({ target: { value } }) => {
    this.setState({ newBookName: value });
  }

  goToBook = async () => {
    // 2. Add new book to DB
    try {
      const { data: { book } } = await axios.post(`${process.env.REACT_APP_API_BASE}/books`, {
        bookName: this.state.newBookName
      },
      {
        headers: {
          "x-logbook-token": sessionStorage.getItem("token"),
        }
      });
      this.props.history.push({ pathname: `/book/${book.id}` });
    } catch (err) {
      if (err && err.response) {
        const { response: { status } } = err;
        if (status === 401) {
          this.props.history.push('/login');
        }
        // TODO: Display error message
        console.log(err);
      } else {
        console.log(err);
      }
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.props.existingBook.bookName) {
      await this.props.updateBook(this.props.existingBook.id, this.state.newBookName)
    } else {
      await this.goToBook();
    }
  };

  render() {
    return (
      <form className="new-book-form" onSubmit={this.handleSubmit}>
        <label style={{margin: "5px 0px"}}>please enter a new book</label>
        <input
          type="text"
          onChange={this.handleInput}
          placeholder="New Book Name"
          value={this.state.newBookName}
          required
        />
        <button type="submit" style={{margin: "5px 0px"}}>Add Book</button>
      </form>
    );
  }
}

export default NewBookForm;
