import React from "react";
import axios from "axios";
import update from "immutability-helper";
import moment from 'moment';

import AddLogForm from "./AddLogForm";
import { isEmpty } from "../utils";
import { Link } from 'react-router-dom';
import "./Book.css";

class Book extends React.Component {
  state = {
    book: {},
    logs: {},
    existingLog: {},
    error: ''
  };

  getAllLogs = async (bookId) => {
    try {
      const { data: { logData, book } } = await axios.get(`${process.env.REACT_APP_API_BASE}/books/${bookId}/logs`,
      {
        headers: {
          "x-logbook-token": sessionStorage.getItem("token"),
        }
      });
      this.setState({ logs: logData, book })
    } catch (err) {
      if (err && err.response) {
        const { response: { status } } = err;
        if (status === 401) {
          this.props.history.push('/login');
        }
      } else {
        console.log(err);
      }
    }
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.getAllLogs(params.bookId);
  }

  deleteBook = async () => {
    const { params } = this.props.match;
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE}/books/${params.bookId}`,
      {
        headers: {
          "x-logbook-token": sessionStorage.getItem("token"),
        }
      });
      this.props.history.push('/');
    } catch (err){
      const { response: { status } } = err;
      if (status === 401) {
        this.props.history.push('/login');
      }
    }
  }

  addLog = async (log) => {
    const { params } = this.props.match;
    try {
      // renaming log to newLog
      const { data: { log: newLog } } = await axios.post(`${process.env.REACT_APP_API_BASE}/books/${params.bookId}/logs`, {
        content: log
      },
      {
        headers: {
          "x-logbook-token": sessionStorage.getItem("token"),
        }
      });
      const logs = update(this.state.logs, { $push: [newLog] });
      const sortedLogs = logs.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
      this.setState({ logs: sortedLogs });
    } catch (err) {
      if (err && err.response) {
        const { response: { status } } = err;
        if (status === 401) {
          this.props.history.push('/login');
        }
      } else {
        console.log(err);
      }
    }
  };

  deleteLog = async (id) => {
    const { params } = this.props.match;
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE}/books/${params.bookId}/logs/${id}`,
      {
        headers: {
          "x-logbook-token": sessionStorage.getItem("token"),
        }
      });
      const index = this.state.logs.findIndex((log) => log.id === id)
      const logs = update(this.state.logs, {$splice: [[index, 1]]})
      this.setState({ logs });
    } catch (err){
      const { response: { status } } = err;
      if (status === 401) {
        this.props.history.push('/login');
      }
    }
  }

  editLog = (id) => {
    const log = this.state.logs.find((log) => log.id === id)
    this.setState({ existingLog: log })
    window.scrollTo(0,0);
  }

  updateLog = async (id, content) => {
    const { params } = this.props.match;
    try {
      // renaming 'log' to 'updatedLog'
      const { data: { log: updatedLog } } = await axios.put(`${process.env.REACT_APP_API_BASE}/books/${params.bookId}/logs/${id}`, {
        content
      },
      {
        headers: {
          "x-logbook-token": sessionStorage.getItem("token"),
        }
      });
      const index = this.state.logs.findIndex((l) => l.id === id);
      // this.state.logs[index] = updatedLog; // didn't work, so we did this:
      const logs = update(this.state.logs, {$splice: [[index, 1]]});
      logs.push(updatedLog);
      // we need to add moment because createdAt is a string
      const sortedLogs = logs.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
      this.setState({ logs: sortedLogs, existingLog: {} });
    } catch (err) {
      if (err && err.response) {
        const { response: { status } } = err;
        if (status === 401) {
          this.props.history.push('/login');
        }
        if (status === 404) {
          this.setState({ existingLog: {} });
          this.setState({ error: 'Log doesn\'t exist' });
        }
      } else {
        console.log(err);
      }
    }
  };

  render() {
    return (
      <>
        <Link to="/" class="my-books">My Books</Link>
        <h1 className="book-title">{this.state.book.book_name}</h1>
        <button onClick={() => {if (window.confirm("Delete this book and all of its logs?")) {this.deleteBook()} }} class="delete-book">Delete Book</button>
        <div>
          { this.state.error }
        </div>
        <AddLogForm addLog={this.addLog} updateLog={this.updateLog} existingLog={this.state.existingLog} />
        <div className="logs">
          {isEmpty(this.state.logs) ? (
            <span>You don&apos;t have any logs in this book yet.</span>
          ) : (
            this.state.logs
              .map((log) => (
                <div className="log" key={log.id}>
                  <button onClick={() => this.deleteLog(log.id)} disabled={ !!this.state.existingLog.content }>X</button>
                  <button onClick={() => this.editLog(log.id)} disabled={ !!this.state.existingLog.content }>Edit</button>
                  <h4 className="log-date">{moment(log.createdAt).format('MMMM Do YYYY, h:mm:ss a')}:</h4>
                  <p dangerouslySetInnerHTML={{ __html: log.content }}></p>
                </div>
              ))
          )}
        </div>
      </>
    );
  }
}

export default Book;
