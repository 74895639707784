import React, { useContext, useState } from "react";
import axios from "axios";
import "./Login.css";

const initialState = {
  email: "",
  password: "",
  emailError: "",
  passwordError: "",
};

function Login(props) {
  const [state, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      try {
        setIsLoading(true);
        const {
          data: { token },
        } = await axios.post(
          `${process.env.REACT_APP_API_BASE}/users/login`,
          state
        );
        sessionStorage.setItem("token", token);
        props.history.push({ pathname: `/` });
        setState(initialState);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const validate = () => {
    let emailError = "";
    let passwordError = "";

    if (state.email.trim() === "") {
      emailError = "email cannot be blank";
      setState((prevState) => ({ ...prevState, emailError }));
    } else {
      setState((prevState) => ({ ...prevState, emailError: "" }));
    }

    if (state.password.trim() === "") {
      passwordError = "password cannot be blank";
      setState((prevState) => ({ ...prevState, passwordError }));
    } else {
      setState((prevState) => ({ ...prevState, passwordError: "" }));
    }

    if (emailError || passwordError) {
      return false;
    }

    return true;
  };

  return (
    <>
      <h1>LOGBOOKS</h1>
      &nbsp;
      <span className="releaseVersion">v.0.0.1</span>
      <p>Sign in</p>
      <form className="loginForm" onSubmit={handleSubmit}>
        <fieldset disabled={isLoading}>
          <label htmlFor="loginEmail">
            <input
              id="loginEmail"
              type="text"
              name="email"
              value={state.email}
              onChange={handleChange}
            />
            <span className="loginEmailError" aria-live="polite">
              {state.emailError}
            </span>
          </label>
          <label htmlFor="loginPassword">
            <input
              id="loginPassword"
              type="password"
              name="password"
              value={state.password}
              onChange={handleChange}
            />
            <span className="loginPasswordError" aria-live="polite">
              {state.passwordError}
            </span>
          </label>
          <input type="submit" value="Sign In" />
          {isLoading && <div>Loading...</div>}
        </fieldset>
      </form>
    </>
  );
}

export default Login;
