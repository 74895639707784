import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import NotFound from "./NotFound";
import Book from "./Book";
import Login from "./Login";
import Signup from './Signup';
import EditUser from './EditUser';
import ManageUser from './ManageUser';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={App}></Route>
      <Route path="/book/:bookId" component={Book}></Route>
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/signup" component={Signup}></Route>
      <Route path="/users/manage" component={ManageUser}></Route>
      <Route path="/users/:userId/edit" component={EditUser}></Route>
      <Route component={NotFound}></Route>
    </Switch>
  </BrowserRouter>
);

export default Router;
