import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";

export default function EditUser (props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const { admin } = jwt.decode(token);
    if (!admin) {
      props.history.push({ pathname: `/` });
    }
    getUserData();
  }, [])

  const getUserData = async () => {
    const { data : { userData } } = await axios.get(`${process.env.REACT_APP_API_BASE}/users/${props.match.params.userId}`, {
      headers: {
        "x-logbook-token": sessionStorage.getItem("token"),
      }
    });
    setEmail(userData.email);
    setPassword(userData.password);
    setAdmin(userData.admin);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE}/users/${props.match.params.userId}`, {
        email,
        password,
        admin,
      }, {
        headers: {
          "x-logbook-token": sessionStorage.getItem("token"),
        }
      });
      props.history.push({ pathname: `/` });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    <h2>Edit User</h2>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="text"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="admin">Admin</label>
        <input
          id="admin"
          type="checkbox"
          name="admin"
          value={admin}
          checked={admin}
          onChange={(event) => setAdmin(!!event.target.checked)}
        />
      </div>
      <input type="submit" value="Sign Up" />
    </form>
    </>
  );
}
