import React from "react";
import axios from "axios";
import update from "immutability-helper";
import moment from 'moment';

import "./App.css";
import NewBookForm from "./NewBookForm";
import BookLink from "./BookLink";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      books: [],
      existingBook: {}
    };
  }

  getAllBooks = async () => {
    try {
      const { data: { bookData } } = await axios.get(`${process.env.REACT_APP_API_BASE}/books`, {
        headers: {
          "x-logbook-token": sessionStorage.getItem("token"),
        }
      });
      this.setState({ books: bookData });
    } catch (err) {
      const { response: { status } } = err;
      if (status === 401) {
        this.props.history.push('/login');
      }
    }
  };

  componentDidMount() {
    this.getAllBooks();
  }

  editBookName = (id) => {
    const book = this.state.books.find((book) => book.id === id)
    this.setState({ existingBook: book })
  }

  updateBook = async (id, bookName) => {
    try {
      // renaming 'book' to 'updatedBook'
      const { data: { book: updatedBook } } = await axios.put(`${process.env.REACT_APP_API_BASE}/books/${id}`, {
        bookName
      },
      {
        headers: {
          "x-logbook-token": sessionStorage.getItem("token"),
        }
      });
      const index = this.state.books.findIndex((b) => b.id === id);
      const books = update(this.state.books, {$splice: [[index, 1]]});
      books.push(updatedBook);
      // we need to add moment because createdAt is a string
      const sortedBooks = books.sort((j, k) => moment(k.createdAt) - moment(j.createdAt))
      this.setState({ books: sortedBooks, existingBook: {} });
    } catch (err) {
      if (err && err.response) {
        const { response: { status } } = err;
        if (status === 401) {
          this.props.history.push('/login');
        }
        let error;
        if (status === 404) {
          error = 'Book doesn\'t exist';
        } else {
          error = 'Something went wrong';
        }
        this.setState({ existingBook: {} });
        this.setState({ error });
      } else {
        console.log(err);
      }
    }
  };

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>LOGBOOKS</h1>
          <span className="releaseVersion">v.0.0.2</span>
        </header>
        <NewBookForm className="App-header" history={this.props.history} existingBook={this.state.existingBook} updateBook={this.updateBook}/>
        <h2>Books:</h2>
        {this.state.books.map((book) => (
          <div key={book.id}>
            <BookLink book={book} />
            <button onClick={() => {this.editBookName(book.id)}}>Edit</button>
          </div>
        ))}
      </div>
    );
  }
}

export default App;
